import React from "react";

export default function Header(props) {
    return (
        <header id="header">
            <div className="app-heading">
                <img id="logo-1" src="https://res.cloudinary.com/dq27r8cov/image/upload/v1631829206/McCollister%27s/autotrader-logo.png" />

                <img id="logo-2" src="https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mccollisters-auto-logistics.png" />

                <img id="logo-3" src="https://res.cloudinary.com/dq27r8cov/image/upload/v1631828378/McCollister%27s/autotraderclassics-logo.png" />

            </div>

        </header>
    )
}