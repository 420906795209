import {
  GET_SETTINGS,
  UPDATE_SETTINGS,
  GET_MAKES,
  FILTER_PORTAL,
  SETTINGS_ERROR,
  CLEAR_SETTINGS_ERROR,
  UNAUTHORIZE_USER,
} from "../actions/types";

export default (state = { settings: null, makes: [], error: null }, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return { ...state, ...action.settings };
    case GET_MAKES:
      return { ...state, makes: action.makes };
    default:
      return state;
  }
};
