import React, { useState, useEffect, useRef } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter, NavLink, useHistory } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";

// Components
import Spinner from "../../components/Spinner/Spinner.js";
import TestimonialCarousel from "../../components/Testimonials/TestimonialCarousel.js";
import HowToCarousel from "../../components/HowTo/HowToCarousel";

import QuoteDetail from "./components/QuoteDetail";

// Form
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";

// Actions
import { createQuote, resetQuoteState } from "../../actions/quote";
import { resetOrderState } from "../../actions/order";

// Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faPlus,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

// Assets
import {
  AwardWinning,
  ZeroUpfront,
  InsuranceCoverage,
  ThereForYou,
  LiveUpdates,
  Experience,
} from "./components/ValueProp.js";

library.add([faTrash, faPlus, faInfoCircle]);

function Quote(props) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [numVehicles, setNumVehicles] = useState(1);
  const [pickupLocationError, setPickupLocationError] = useState(null);
  const [deliveryLocationError, setDeliveryLocationError] = useState(null);
  const [showOperableToolTip, setShowOperableToolTip] = useState(null);
  const [makesList, setMakesList] = useState([]);

  let history = useHistory();

  const handleResetAll = () => {
    props.dispatch(resetQuoteState());
    props.dispatch(
      resetOrderState(() => {
        history.push("/app/quote");
      })
    );
  };

  useEffect(() => {
    if (props.makes) {
      let makes = props.makes;

      let makesList = [];
      makes.forEach((item) => {
        let modelsList = [];
        item.models.forEach((model) => {
          modelsList.push({
            label: model.model,
            value: model.model,
            pricingClass: model.pricingClass,
          });
        });

        modelsList = [
          ...modelsList,
          {
            label: "Other - Sedan",
            value: "Other Sedan",
            pricingClass: "Sedan",
          },
          { label: "Other - SUV", value: "Other - SUV", pricingClass: "SUV" },
          { label: "Other - Van", value: "Other Van", pricingClass: "VAN" },
          {
            label: "Other Pickup Truck",
            value: "Other - Pickup",
            pricingClass: "Pick up 4 doors",
          },
          {
            label: "Other - Pre-1975 Classic",
            value: "Other Pre-1975 Classic",
            pricingClass: "Pick up 4 doors",
          },
        ];

        makesList.push({
          label: item.make,
          value: item.make,
          models: modelsList,
        });
      });
      setMakesList(makesList);
    }
  }, [props.makes]);

  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      vehicles: [{ make: "Select One", model: "Select One", Operable: "Yes" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "vehicles",
  });

  const tooltip = useRef();
  const handleClick = (e) => {
    if ((tooltip.current && tooltip.current.contains(e.target)) === false) {
      setShowOperableToolTip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const toggleOperableToolTip = (index) => {
    setShowOperableToolTip(index);
  };

  const onSubmit = (data) => {
    setShowSpinner(true);
    props.dispatch(
      createQuote(data, () => {
        setShowSpinner(false);
      })
    );
  };
  console.log(props);
  return (
    <div id="quote">
      {props.quote && props.quote.error && (
        <div className="error">{props.quote.error}</div>
      )}

      {showSpinner && <Spinner />}
      <div className="hero-container">
        <div className="hero-inner">
          <div className="hero-left">
            <h2>Ship Your Vehicle With McCollister’s Auto Logistics</h2>
            <div className="subhead-container">
              <p>
                Don’t be worried about moving that hard-to-find beauty that you
                just put in the research and time to make yours! Long-distance
                buying and the need to ship a car back home is the norm in
                todays world. McCollister’s Auto Logistics should be your first
                choice.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Update this for new instances 
      {!props.quote && (
        <div className="app-intro">
          <h1>Ship Your Vehicle with McCollister's Auto Logistics</h1>

          <p>
            Never lose track of your car's location when you ship with
            McCollister's Auto Logistic Services. We offer fully-transparent,
            real-time auto logistics so that you know where your car is 24/7.
            Calculate your real-time quote now!
          </p>
        </div>
      )} */}
      <div className="post-hero-copy-container">
        <p>
          Welcome to the McCollister’s Shipping Portal. The portal is an easy
          and convenient way to calculate a quote, have the quote sent to you
          via email and book an order for transport.
        </p>

        <p>
          If you have any questions, feel free to reach out to our selected
          partner McCollister’s Auto Logistics by using the chat feature below
          or contact via email at{" "}
          <a href="mailto:autologistics@mccollisters.com">
            autologistics@mccollisters.com
          </a>
          . We look forward to servicing your auto transport need!
        </p>
      </div>
      {!props.quote && (
        <form
          id="get-quote-form"
          className="quote-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="hidden"
            ref={register}
            value="6143b83c56651100181b762b"
            name="portalId"
          />
          <input
            type="hidden"
            ref={register}
            value="AutoTrader Customer Portal"
            name="instance"
          />

          <div className="quote-form-row">
            <div className="form-input">
              <label htmlFor="pickup">Pick Up</label>
              <Controller
                as={
                  <PlacesAutocomplete
                    searchOptions={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                      types: ["(regions)"],
                    }}
                    onSelect={(e) => {
                      setValue("pickup", e);
                      setPickupLocationError(null);
                    }}
                    onError={() =>
                      setPickupLocationError("Location not found.")
                    }
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => {
                      return (
                        <div className="autocomplete-form-field">
                          <input
                            {...getInputProps({
                              placeholder: "Enter Zip or City",
                              className: "location-search-input",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              suggestion.description = suggestion.description.replace(
                                ", USA",
                                ""
                              );

                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";

                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    }}
                  </PlacesAutocomplete>
                }
                control={control}
                rules={{ required: "This field is required." }}
                name="pickup"
              />
              {pickupLocationError && (
                <p className="form-error-message">{pickupLocationError}</p>
              )}
              <ErrorMessage errors={errors} name="pickup">
                {({ message }) => (
                  <p className="form-error-message">{message}</p>
                )}
              </ErrorMessage>
            </div>
            <div className="form-input">
              <label htmlFor="delivery">Delivery</label>
              <Controller
                as={
                  <PlacesAutocomplete
                    searchOptions={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                      types: ["(regions)"],
                    }}
                    onSelect={(e) => {
                      setValue("delivery", e);
                      setDeliveryLocationError(null);
                    }}
                    onError={() =>
                      setDeliveryLocationError("Location not found.")
                    }
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="autocomplete-form-field">
                        <input
                          {...getInputProps({
                            placeholder: "Enter Zip or City",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            suggestion.description = suggestion.description.replace(
                              ", USA",
                              ""
                            );

                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                }
                control={control}
                rules={{ required: "This field is required." }}
                name="delivery"
              />
              {deliveryLocationError && (
                <p className="form-error-message">{deliveryLocationError}</p>
              )}
              <ErrorMessage errors={errors} name="delivery">
                {({ message }) => (
                  <p className="form-error-message">{message}</p>
                )}
              </ErrorMessage>
            </div>
            <div className="form-input">
              <label htmlFor="transportType">Transport Type</label>
              <Controller
                as={
                  <Select
                    options={[
                      { label: "OPEN", value: "OPEN" },
                      { label: "ENCLOSED", value: "ENCLOSED" },
                    ]}
                    placeholder="Select One"
                  />
                }
                control={control}
                rules={{ required: "This field is required." }}
                name="transportType"
              />
              <ErrorMessage errors={errors} name="transportType">
                {({ message }) => (
                  <p className="form-error-message">{message}</p>
                )}
              </ErrorMessage>
            </div>
          </div>

          <div className="quote-form-row">
            <div className="form-input">
              <label htmlFor="customerFirstName">First Name</label>
              <input type="text" name="customerFirstName" ref={register} />
            </div>
            <div className="form-input">
              <label htmlFor="customerLastName">Last Name</label>
              <input type="text" name="customerLastName" ref={register} />
            </div>
          </div>

          <div className="quote-form-vehicles">
            {fields.map((item, index) => {
              let selectedMake;
              selectedMake = watch(`vehicles[${index}].make`);

              return (
                <div key={item.id} className="quote-form-row sub-form">
                  <div className="form-input">
                    <label htmlFor={`vehicles[${index}].make`}>Make</label>

                    <Controller
                      as={
                        <Select options={makesList} placeholder="Select One" />
                      }
                      control={control}
                      name={`vehicles[${index}].make`}
                      defaultValue={item.make}
                      rules={{
                        required: true,
                        validate: (value) => value !== "Select One",
                      }}
                    />

                    <ErrorMessage
                      errors={errors}
                      name={`vehicles[${index}].make`}
                    >
                      {() => (
                        <p className="form-error-message">
                          Please select a make.
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="form-input">
                    <label htmlFor={`vehicles[${index}].model`}>Model</label>

                    {selectedMake && (
                      <div>
                        <Controller
                          as={
                            <Select
                              options={selectedMake.models}
                              placeholder="Select One"
                            />
                          }
                          control={control}
                          rules={{
                            validate: (value) => value !== "Select One",
                            message: "This field is required.",
                          }}
                          name={`vehicles[${index}].model`}
                          // rules={{ required: "This field is required." }}
                          defaultValue={item.model}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`vehicles[${index}].model`}
                        >
                          {() => (
                            <p className="form-error-message">
                              Please select a model.
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                    )}
                  </div>

                  <div className="form-input">
                    <label htmlFor={`vehicles[${index}].operable`}>
                      Is Vehicle Operable?{" "}
                      <FontAwesomeIcon
                        onClick={() => toggleOperableToolTip(index)}
                        className="icon-info-circle"
                        icon="info-circle"
                      />
                    </label>
                    {showOperableToolTip === index && (
                      <div className="form-tooltip" ref={tooltip}>
                        An operable vehicle means it can brake, roll, steer and
                        start independently.
                      </div>
                    )}
                    <Controller
                      as={
                        <Select
                          options={[
                            { label: "Yes", value: "Yes" },
                            { label: "No", value: "No" },
                          ]}
                          placeholder="Select One"
                        />
                      }
                      control={control}
                      rules={{
                        validate: (value) => value !== "Is Operable",
                        message: "This field is required.",
                      }}
                      defaultValue={item.operable}
                      // rules={{ required: "This field is required." }}
                      name={`vehicles[${index}].operable`}
                    />

                    <ErrorMessage
                      errors={errors}
                      name={`vehicles[${index}].operable`}
                    >
                      {() => (
                        <p className="form-error-message">
                          Select if vehicle is operable.
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                  {numVehicles > 1 && (
                    <div className="form-input-icon-container">
                      <button
                        className="form-input-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          remove(index);
                          setNumVehicles(numVehicles - 1);
                        }}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="form-button-panel">
            {numVehicles < 3 && (
              <button
                className="form-add"
                type="button"
                onClick={() => {
                  append({
                    make: "Select Make",
                    model: "Select Model",
                    operable: "Is Operable",
                  });
                  setNumVehicles(numVehicles + 1);
                }}
              >
                <FontAwesomeIcon icon="plus" /> Another Vehicle
              </button>
            )}
          </div>

          <p>
            Please enter your email address below if you would like receive an
            email confirmation of your quote to book at a later date. Don’t
            worry, we’re not going to sell it, trade it, or abuse any
            information you submit to us.
          </p>
          <div id="email-row" className="quote-form-row">
            <div className="form-input">
              <label htmlFor="customerEmail">Email Address (optional)</label>
              <input type="text" name="customerEmail" ref={register} />
            </div>
          </div>
          <div className="form-button-panel">
            <div className="link-container" onClick={() => handleResetAll()}>
              Cancel and start a new quote.
            </div>
            <button className="form-submit" type="submit">
              Get Quote
            </button>
          </div>
        </form>
      )}

      {props.quote && (
        <QuoteDetail quote={props.quote} serviceLevels={props.serviceLevels} />
      )}
      <div className="headline-container">
        <h1>HOW OUR AUTO TRANSPORT SERVICE WORKS</h1>
      </div>
      <HowToCarousel />
      <div className="headline-container">
        <h1>MCCOLLISTER’S EXCELLENCE. DELIVERED EVERY DAY</h1>
      </div>
      <div className="value-prop-container">
        <AwardWinning />
        <ZeroUpfront />
        <InsuranceCoverage />
        <ThereForYou />
        <LiveUpdates />
        <Experience />
      </div>
      <TestimonialCarousel />
      <div className="ready-to-quote-container">
        <div className="ready-to-quote-inner">
          <div className="ready-to-quote-left">
            <h2>
              Ready to start the best auto transport experience available?{" "}
            </h2>
          </div>
          <div className="ready-to-quote-right">
            <button
              onClick={() =>
                document
                  .getElementById("get-quote-form")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Get an instant quote
            </button>
          </div>
        </div>
      </div>
      {/* {!props.quote && (
        <div>
          <h2>McCollister's Car Transport Services Near You</h2>

          <p>
            Cross-country car transportation has never looked so easy. We
            understand how hard relocating can be. With available agents across
            the country, we can pick up and ship your car anywhere in the
            continental U.S.
          </p>

          <h2>Auto Logistics Technology</h2>

          <p>
            Stay on top of your vehicle, all day long, with our cloud-based
            portal and GPS tracking. You'll receive live updates with real-time
            timelines about your vehicle's loading and delivery. If you prefer
            more of a human touch, McCollister's offers award-winning customer
            service 7 days a week.
          </p>
        </div>
      )} */}
      {!props.quote && (
        <div className="order-link">
          Already have a quote?{" "}
          <NavLink to="/book">Book your order here.</NavLink>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    makes: state.settings.makes,
    quote: state.quote.quote,
    serviceLevels: state.settings[0]?.serviceLevels,
  };
};

export default compose(withRouter, connect(mapStateToProps))(Quote);
