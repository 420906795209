import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import {
  BsFillCalendarDateFill,
  BsFillCalendarWeekFill,
  BsCalendarCheckFill,
  BsFillPiggyBankFill,
} from "react-icons/bs";

function QuoteDetail(props) {
  const [expandOption1, setExpandOption1] = useState(false);
  const [expandOption2, setExpandOption2] = useState(false);
  const [expandOption3, setExpandOption3] = useState(false);
  const [expandOption4, setExpandOption4] = useState(false);

  let quote = props.quote;

  const transportTypeValue = () => {
    if (quote.transportType === "ENCLOSED") {
      return <span>Enclosed</span>;
    }
    return <span>Open</span>;
  };

  return (
    <div className="quote-detail view-content-container">
      <p>
        Please find your quote below. Quotes are valid for 30 days from the date
        they were created. From here, you can book your order or please note and
        save your confirmation code if you did not choose to have the quote
        emailed to you. You can always return later to enter your code and book
        your transport.
      </p>

      <div className="view-header">
        <h3>Confirmation Code: {quote.customerCode}</h3>
      </div>

      <div className="quote-detail-container">
        <div className="quote-detail-info">
          All of the pricing options below include:
          <ul>
            <li>Door-to-door service for both pick up and delivery.</li>
            <li>
              Guaranteed on time delivery (rental car provided in event of
              driver caused delay on delivery).
            </li>
            <li>Insurance coverage included.</li>
            <li>All taxes and fees are included in this price quote.</li>
            {quote.transportType === "ENCLOSED" && (
              <li className="color-red">
                Please note that for enclosed transport, in order to meet your
                required pick up date, we reserve the right to use a flatbed to
                pick up the vehicle and bring it to a secure terminal location
                while awaiting arrival of the enclosed transport truck.
              </li>
            )}
          </ul>
        </div>

        <div className="pricing-options">
          <div className="option">
            <BsFillPiggyBankFill className="illustrative-icon" />
            <h3>Least Expensive</h3>
            <h2>${quote.totalPricing["7"].totalPortal}</h2>
            <div className="vehicle-details-container">
              <div onClick={() => setExpandOption1(!expandOption1)}>
                Vehicle Details{" "}
                {expandOption1 ? <FiChevronDown /> : <FiChevronUp />}
              </div>
              {expandOption1 && (
                <div className="vehicle-details">
                  {quote.vehicleQuotes.map((v) => {
                    return (
                      <p>
                        {v.make} {v.model}
                        {quote.vehicleQuotes.length > 1 &&
                          ": $" + v.calculatedQuotes[3].totalPortal}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
            <p>
              This is a “standby” option. Your pickup date can be any time after
              your 1st available ship date with no guarantees on how long it
              might take. You must have your vehicle ready to be picked up on
              short notice.
            </p>
          </div>

          <div className="option">
            <BsCalendarCheckFill className="illustrative-icon" />
            <h3>Flexible Dates</h3>
            <h2>${quote.totalPricing["5"].totalPortal}</h2>
            <div className="vehicle-details-container">
              <div onClick={() => setExpandOption2(!expandOption2)}>
                Vehicle Details{" "}
                {expandOption2 ? <FiChevronDown /> : <FiChevronUp />}
              </div>
              {expandOption2 && (
                <div className="vehicle-details">
                  {quote.vehicleQuotes.map((v) => {
                    return (
                      <p>
                        {v.make} {v.model}
                        {quote.vehicleQuotes.length > 1 &&
                          ": $" + v.calculatedQuotes[2].totalPortal}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
            <p>
              Pickup within 5 business days (M-F) of your 1st available ship
              date.
            </p>
          </div>

          <div className="option">
            <BsFillCalendarWeekFill className="illustrative-icon" />
            <h3>Guaranteed Dates</h3>
            <h2>${quote.totalPricing["3"].totalPortal}</h2>
            <div className="vehicle-details-container">
              <div onClick={() => setExpandOption3(!expandOption3)}>
                Vehicle Details{" "}
                {expandOption3 ? <FiChevronDown /> : <FiChevronUp />}
              </div>
              {expandOption3 && (
                <div className="vehicle-details">
                  {quote.vehicleQuotes.map((v) => {
                    return (
                      <p>
                        {v.make} {v.model}
                        {quote.vehicleQuotes.length > 1 &&
                          ": $" + v.calculatedQuotes[1].totalPortal}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
            <p>
              Pickup within 3 business days (M-F) of your 1st available ship
              date.
            </p>
          </div>

          <div className="option">
            <BsFillCalendarDateFill className="illustrative-icon" />
            <h3>Exact Date</h3>
            <h2>${quote.totalPricing["1"].totalPortal}</h2>
            <div className="vehicle-details-container">
              <div onClick={() => setExpandOption4(!expandOption4)}>
                Vehicle Details{" "}
                {expandOption4 ? <FiChevronDown /> : <FiChevronUp />}
              </div>
              {expandOption4 && (
                <div className="vehicle-details">
                  {quote.vehicleQuotes.map((v) => {
                    return (
                      <p>
                        {v.make} {v.model}
                        {quote.vehicleQuotes.length > 1 &&
                          ": $" + v.calculatedQuotes[0].totalPortal}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
            <p>
              Pickup will be scheduled for the exact business day (M-F) that you
              select.
            </p>
          </div>
        </div>

        <div className="pickup-delivery-details">
          <div className="row">
            <span className="row-label">Pickup:</span>
            <span>{quote.pickup}</span>
          </div>

          <div className="row">
            <span className="row-label">Delivery:</span>
            <span>{quote.delivery}</span>
          </div>

          {quote.customerFullName && (
            <div className="row">
              <span className="row-label">Your Name:</span>
              <span>{quote.customerFullName}</span>
            </div>
          )}

          {quote.customerEmail && (
            <div className="row">
              <span className="row-label">Your Email:</span>
              <span>{quote.customerEmail}</span>
            </div>
          )}

          <div className="row">
            <span className="row-label">Miles:</span>
            <span>{quote.miles}</span>
          </div>

          <div className="row">
            <span className="row-label">Transport Type:</span>
            {transportTypeValue()}
          </div>

          {quote.transitTime && (
            <div className="row">
              <span className="row-label">Transit Time:</span>
              <span>
                {quote.transitTime[0]} - {quote.transitTime[1]} Days
              </span>
            </div>
          )}

          <div className="action-row">
            {props.quote && (
              <div className="button-container">
                <NavLink to="/order">
                  <button className="form-submit">Book Order</button>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuoteDetail;
